import React from 'react';

import './intervals.css';

function Intervals(props) {
    console.log(props.data);
    if (!props.data || props.data.notes.length === 0) return <div>-</div>;

    const intervalNotes = props.data.notes.map((note, index) => {
        return (
            <td className="intervals-item" key={index} onClick={() => props.addChord(note)}>
                {note}
            </td>
        );
    });

    const chordsList = props.data.triads.map((note, index) => {
        return (
            <td className="intervals-item" key={index} onClick={() => props.addChord(note)}>
                {note}
            </td>
        );
    });

    const chords7List = props.data.sevenths.map((note, index) => {
        return (
            <td className="intervals-item" key={index} onClick={() => props.addChord(note)}>
                {note}
            </td>
        );
    });

    return (
        <div className="intervals">
            <table>
                <tbody>
                    <tr>
                        <th>I</th>
                        <th>II</th>
                        <th>III</th>
                        <th>IV</th>
                        <th>V</th>
                        <th>VI</th>
                        <th>VII</th>
                    </tr>
                    <tr>{intervalNotes}</tr>
                    <tr>{chordsList}</tr>
                    <tr>{chords7List}</tr>
                </tbody>
            </table>
        </div>
    );
}

export default Intervals;
