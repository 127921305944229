import React from 'react';
import './login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch } from 'react-redux';
import { signInLocal } from '../../../redux/actions/authAction';
import { useGoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../../utils/refreshToken';

export default function Login() {
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        event.preventDefault();
        var user = {
            email: 'anonymous@gmail.com',
            familyName: 'anonymous',
            givenName: 'anonymous',
            googleId: 'anonymous',
            imageUrl: '/images/anonymous.jpg',
            name: 'anonymous',
        };

        dispatch(signInLocal(user));
    };

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    const handleLogin = async (googleData) => {
        const res = await fetch('/api/v1/auth/google', {
            method: 'POST',
            body: JSON.stringify({
                token: googleData.tokenId,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await res.json();
        console.log(data);
    };

    const onSuccess = (res) => {
        console.log('Login Success: currentUser:', res.profileObj);
        refreshTokenSetup(res);
        dispatch(signInLocal(res.profileObj));
    };

    const onFailure = (res) => {
        console.log('Login failed: res:', res);
    };

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId,
        isSignedIn: false,
        accessType: 'offline',
        // responseType: 'code',
        //prompt: 'consent',
    });

    return (
        <div className="login">
            <div className="login-header">
                <div className="login-header-logo">
                    <div className="login-header-logo-background">
                        <div className="login-header-logo-font non-selectable">H</div>
                    </div>
                    <div className="login-header-logo-label non-selectable">
                        Harmonies<div>&trade;</div>
                    </div>
                </div>
            </div>

            <div className="login-form">
                <div className="login-service" onClick={signIn}>
                    <div className="login-service-logo">
                        <FontAwesomeIcon icon={['fab', 'google']} />
                    </div>
                    <div className="login-service-label">Login in with google</div>
                </div>
                <div className="login-service" onClick={handleSubmit}>
                    <div className="login-service-logo">
                        <FontAwesomeIcon icon={['far', 'user']} />
                    </div>
                    <div className="login-service-label">Anonymous user</div>
                </div>
            </div>
        </div>
    );
}
