import React from 'react';
import './account.css';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Account() {
    const user = useSelector((state) => state.authReducer.user);

    const handleChange = (ev) => {
        ev.persist();
        //dispatch({ type: UPD_HOUR, payload: { ...hour, [ev.target.name]: ev.target.value } });
    };

    return (
        <div className="profile">
            <div className="form">
                <h2 className="title">Profile</h2>
                <div className="form-body">
                    <div className="form-item">
                        <div className="form-label">Name</div>
                        <div className="form-input">
                            <input type="text" value={user.name} onChange={handleChange}></input>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="form-label">Email</div>
                        <div className="form-input">
                            <input type="text" value={user.email} onChange={handleChange}></input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
