import React, { useState, useEffect } from 'react';
import Circle from '../../../components/circle';
import Intervals from '../../../components/intervals';
import Notes from '../../../components/notes';
import Keyboard from '../../../components/keyboard';
import Modes from '../../../components/modes';
import Sheet from '../../../components/sheet';
import ChordProgression from '../../../components/chordProgressionBox';
import ChordProgressionBoxTools from '../../../components/chordProgressionTools';
import './circlekeyboard.css';

export default function CircleKeyboard() {
    const [tonic, settonic] = useState(0);
    const [config, setconfig] = useState({
        showBlackKeys: false,
    });
    const [progression, setProgression] = useState(['Dm7', 'CM7', 'Fm']);
    const [modeId, setModeId] = useState(1);
    const [harmonization, setHarmonization] = useState({ notes: [], triads: [], sevenths: [], ninths: [] });
    const modeslist = [
        { id: 0, label: 'Lydian', intervalSequence: [1, 1, 1, 0.5, 1, 1, 0.5] },
        { id: 1, label: 'Major/Ionian', intervalSequence: [1, 1, 0.5, 1, 1, 1, 0.5] },
        { id: 2, label: 'Mixolidian', intervalSequence: [1, 1, 0.5, 1, 1, 0.5, 1] },
        { id: 3, label: 'Dorian', intervalSequence: [1, 0.5, 1, 1, 1, 0.5, 1] },
        { id: 4, label: 'Minor/Aeolian', intervalSequence: [1, 0.5, 1, 1, 0.5, 1, 1] },
        { id: 5, label: 'Phygian', intervalSequence: [0.5, 1, 1, 1, 0.5, 1, 1] },
        { id: 6, label: 'Locrian', intervalSequence: [0.5, 1, 1, 0.5, 1, 1, 1] },
    ];

    useEffect(() => {
        updateIntervals({ id: 0, label: 'C' }, 1);
    }, []);

    const notes = [
        { id: 0, label: 'C' },
        { id: 1, label: 'C#' },
        { id: 2, label: 'D' },
        { id: 3, label: 'D#' },
        { id: 4, label: 'E' },
        { id: 5, label: 'F' },
        { id: 6, label: 'F#' },
        { id: 7, label: 'G' },
        { id: 8, label: 'G#' },
        { id: 9, label: 'A' },
        { id: 10, label: 'A#' },
        { id: 11, label: 'B' },
        { id: 12, label: 'C' },
        { id: 13, label: 'C#' },
    ];

    const notesList = notes.filter((note) => note.id < 12);
    const changeTonic = (id) => {
        let tonic = notes[id];
        settonic(id);
        updateIntervals(tonic, modeId);
    };

    const addChord = (chord) => {
        setProgression([...progression, chord]);
    };

    const delChord = (chord) => {
        const newTodos = progression.filter((t) => t !== chord);
        setProgression(newTodos);
    };

    const updChord = (index, chord) => {
        const newTodos = [...progression];
        newTodos[index] = chord;
        setProgression(newTodos);
    };

    const changeMode = (_modeId) => {
        setModeId(_modeId);
        let note = notes[tonic];
        updateIntervals(note, _modeId);
    };

    const updateIntervals = (note, _modeId) => {
        let harmonization = {
            notes: [],
            triads: ['CM', 'DM', 'Em', 'FM', 'G', 'Am', 'Bmo'],
            sevenths: ['CM7', 'DM7', 'Em7', 'FM7', 'G7', 'Am7', 'Bm7b5'],
            ninths: [],
        };

        harmonization.notes.push(note.label);

        const intervals = modeslist[_modeId].intervalSequence;
        let currentNote = note.id;
        let element;
        let interval;
        console.log('%c Harmonization', 'color:orange; font-weight: bolder');
        console.log({ currentNote, note, _modeId });

        for (let index = 0; index < intervals.length; index++) {
            if (currentNote === 12) currentNote = 0;
            if (currentNote === 13) currentNote = 1;

            interval = intervals[index];

            if (interval === 1) currentNote = currentNote + 2;
            else currentNote = currentNote + 1;

            element = notes[currentNote];
            harmonization.notes.push(element.label);
        }

        console.log(harmonization);
        setHarmonization(harmonization);
    };

    return (
        <div className="circleKeyboard">
            <Notes changeTonic={changeTonic} data={notesList} tonic={tonic} />
            <div>
                <Circle changeTonic={changeTonic} tonic={tonic} config={config} />
                <Modes modeslist={modeslist} modeId={modeId} changeMode={changeMode} />
            </div>
            <div className="harmonization">
                <h3>
                    Harmonization {modeslist[modeId].label} {notes[tonic].label}
                </h3>
                <Intervals addChord={addChord} delChord={delChord} updChord={updChord} data={harmonization} />
                <br />
                <h3>Create Progression</h3>
                <div className="chordprogressionDiv">
                    <ChordProgressionBoxTools />
                    <ChordProgression
                        progression={progression}
                        addChord={addChord}
                        delChord={delChord}
                        updChord={updChord}
                    />
                </div>
                <div>
                    <Sheet />
                </div>
            </div>
        </div>
    );
}
