import { REDIRECT } from '../actions/commonAction';

export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';

function signInLocal(user) {
    return (dispatch, getState) => {
        dispatch({ type: SIGNIN, payload: { ...user, active: true } });
        dispatch({ type: REDIRECT, payload: '/home' });
    };
}

function signOut() {
    return (dispatch, getState) => {
        dispatch({ type: SIGNOUT, payload: { active: false } });
        dispatch({ type: REDIRECT, payload: '/login' });
    };
}

export { signInLocal, signOut };
