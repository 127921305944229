import React from 'react';
import './modes.css';

export default function Modes(props) {
    if (!props.modeslist) return <div>-</div>;
    const modes = props.modeslist.map((mode, index) => {
        return (
            <div
                key={index}
                className={props.modeId === mode.id ? 'modes-item-active' : 'modes-item'}
                onClick={() => props.changeMode(mode.id)}
            >
                {mode.label}
            </div>
        );
    });
    return <div className="modes">{modes}</div>;
}
