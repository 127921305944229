import React from "react";
export default function Media() {
	return (
		<div className="profile">
			<div className="form">
				<h2 className="title">Social Media</h2>
			</div>
		</div>
	);
}
